@use 'sass:math';

@import './typography';
@import './transitions';

.btn {
    @extend %default-font-medium;
    @extend %default-transition;
    display: inline-block;
    padding: 22px 40px;
    font-size: math.div(18, 16) + 0rem;
    line-height: math.div(25, 16) + 0rem;
    letter-spacing: 1px;
    color: var(--color-black);
    text-decoration: none;
    appearance: none;
    border: none;
    border-radius: 0;
    cursor: pointer;
    background: var(--color-white);

    &:hover {
        background: var(--color-light-green);
    }

    &--no-hover:hover {
        background: var(--color-white);
        cursor: default;
    }
}