$base-font-size: 16;

%default-font {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

%default-font-medium {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

%default-font-bold {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}