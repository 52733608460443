@import './assets/css/typography';
@import './assets/css/colors';
@import './assets/css/default-styles';

body,
html {
    width: 100%;
    height: 100%;
}

html {
    font-size: $base-font-size + 0px;
}

body {
    @extend %default-font;
    color: var(--color-black);
}