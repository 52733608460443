@use 'sass:math';
@import '../../assets/css/btn';

.top-logo {
    transform: translateX(-50%);
    position: absolute;
    top: 30px;
    left: 50%;
    display: block;
    width: 110px;
    font-size: 0;
    line-height: 0;

    img {
        width: 100%;
        height: auto;
    }
}

.home-header {
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: url('../../../public/images/home/home-bg.jpg') no-repeat center center / cover black;

    &__inner {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        min-height: 100vh;
        margin-inline: auto;
        padding: 300px 0 150px;
    }

    &__title {
        display: block;
        max-width: 1200px;
        margin: 0 auto;
        color: var(--color-white);
        font-size: math.div(45, $base-font-size) + 0rem;
        line-height: math.div(50, $base-font-size) + 0rem;
        letter-spacing: -2.62px;
        text-align: center;
    }

    &__btn {
        margin-top: 50px;
    }
}

@media (min-width: 850px) {
    .home-header {
        &__title {
            font-size: math.div(65, $base-font-size) + 0rem;
            line-height: math.div(70, $base-font-size) + 0rem;
        }

        &__btn {
            margin-top: 60px;
        }
    }
}

@media (min-width: 1100px) {
    .home-header {
        &__title {
            font-size: math.div(85, $base-font-size) + 0rem;
            line-height: math.div(90, $base-font-size) + 0rem;
        }

        &__btn {
            margin-top: 80px;
        }
    }
}